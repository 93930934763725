import { render, staticRenderFns } from "./thirdresource.vue?vue&type=template&id=8b81dee6&scoped=true&"
import script from "./thirdresource.vue?vue&type=script&lang=js&"
export * from "./thirdresource.vue?vue&type=script&lang=js&"
import style0 from "./thirdresource.vue?vue&type=style&index=0&id=8b81dee6&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8b81dee6",
  null
  
)

export default component.exports