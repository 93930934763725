<template>
  <div id="modelindex">
    <!--内容区域布局-->
    <LiefengContent>
      <!--功能名称，定义则显示-->
      <template v-slot:title>第三方资源统计</template>
       <!--左则工具条-->
      <template v-slot:toolsbarLeft>
       
      </template>
       <!--右则工具条-->
      <template v-slot:toolsbarRight>
   <div class="tooldiv">区：</div>
    <Select v-model="selectArea" filterable :max-tag-count="2" multiple @on-change="loadAreaList" placeholder="请选择区" clearable style="width:250px">
        <Option v-for="item in areaList" :value="item.value" :key="item.value">{{ item.label }}</Option>
    </Select>
    <div class="tooldiv">街道：</div>
    <Select v-model="selectStree" filterable :max-tag-count="2" multiple @on-change="loadStreeList" style="width:250px" placeholder="请选择街道" clearable :disabled="disabledStree">
        <Option v-for="item in streeList" :value="item.value" :key="item.value">{{ item.label }}</Option>
    </Select>
    <div class="tooldiv"> 社区：</div>
      <Select multiple style="width:290px" filterable :max-tag-count="2" @on-change="loadCommunityList"  v-model="selectedcommunity"  placeholder="全部社区" clearable :disabled="disabledCommunity">
        <Option v-for="item in communitylist" :value="item.value" :key="item.value">{{ item.label }}</Option>
        </Select> 
       <Button type="info" icon="ios-search"   @click="loadTotalData"
                  >确定</Button
                >
      </template>
       <!--内容区域-->
      <template v-slot:contentArea>
        <div style="display:flex;align-items:stretch;height:200px;justify-content: flex-start;margin:20px 0;">
         <Card  class="cardsty">
        <div>
            <h2>链接资源数（个）</h2>
            <br/>
            <p><span>广告：9</span><span>小程序：8</span><span> 链接：3</span>
            </p>
            <p style="color:#fff000">合计：20
            </p>
        </div>
         </Card>
             <Card  class="cardsty">
       <div>
            <h2>累计访问量（次）</h2>
            <br/>
            <p><span>广告：9</span><span>小程序：8</span><span> 链接：3</span>
            </p>
            <p style="color:#fff000">合计：20
            </p>
        </div>
         </Card>
          
        </div>

         <Card style="width:96%;margin:50px 2% 0 2%;">
                <div slot="title">小程序资源统计：
                  <Select v-model="miniVisit" style="width:150px;margin-right: 10px" placeholder="选择访问类型"  @on-change="loadPublishData">
                    <Option v-for="item in miniVisitOption" :value="item.value" :key="item.value">{{ item.label }}</Option>
                  </Select>
                  <RadioGroup v-model="timetype" @on-change="loadPublishData">
                <Radio label="day" border>昨天</Radio>
                <Radio label="week" border>前7天</Radio>
                <Radio label="month" border>前30天</Radio>
                 <Radio label="all" border>全部</Radio>
                </RadioGroup>
                <span style="margin-left:50px;">自定义时间段：</span>
<DatePicker v-model="selectdate" format="yyyy/MM/dd" type="daterange" placement="bottom-end" placeholder="选择时间区域" style="width: 250px"></DatePicker>
    <Button type="primary" style="margin:0 10px;" icon="ios-search" @click="timetype='custom';loadPublishData();">查询</Button>
                </div>  
                <p>
                   <VeHistogram :data="chartData" :settings="settingsparam"></VeHistogram>
                </p>
                 <p style="text-align:right;">
                   <Button type="default" style="margin:10px;" icon="md-download" @click="exportData">导出表格</Button>
                    </p>
                 <p>
                  <Table ref="lftable"  border :columns="talbeColumns" :data="tableData" show-summary>
                </Table>
                </p>
               
          </Card>
         <Card style="width:96%;margin:50px 2% 0 2%;">
                <div slot="title">链接资源统计：
                   <Select v-model="linkVisit" style="width:150px;margin-right: 10px" placeholder="选择访问类型" @on-change="loadPublishData2">
                    <Option v-for="item in linkVisitOption" :value="item.value" :key="item.value">{{ item.label }}</Option>
                  </Select>
                  <RadioGroup v-model="timetype2" @on-change="loadPublishData2">
                <Radio label="day" border>昨天</Radio>
                <Radio label="week" border>前7天</Radio>
                <Radio label="month" border>前30天</Radio>
                 <Radio label="all" border>全部</Radio>
                </RadioGroup>
                <span style="margin-left:50px;">自定义时间段：</span>
<DatePicker v-model="selectdate2" format="yyyy/MM/dd" type="daterange" placement="bottom-end" placeholder="选择时间区域" style="width: 250px"></DatePicker>
    <Button type="primary" style="margin:0 10px;" icon="ios-search" @click="timetype2='custom';loadPublishData2();">查询</Button>
                </div>  
                <p>
                   <VeHistogram :data="chartData2" :settings="settingsparam2"></VeHistogram>
                </p>
                 <p style="text-align:right;">
                   <Button type="default" style="margin:10px;" icon="md-download" @click="exportData2">导出表格</Button>
                    </p>
                 <p>
                  <Table ref="lftable2"  border :columns="talbeColumns2" :data="tableData2" show-summary>
                </Table>
                </p>
               
          </Card>

      </template>
    </LiefengContent>

  </div>
</template>
<script>
//@route('/statisticsthirdresource')
import LiefengContent from "@/components/LiefengContent.vue";
import VeHistogram from "v-charts/lib/histogram.common";
import LiefengTable from "@/components/LiefengTable.vue";
export default {
  name: "statisticsthirdresource",
  components: {
    LiefengContent,
    VeHistogram,
    LiefengTable,
  },
  data() {
    return {
      chartData: {},
      talbeColumns: [],
      tableData: [],
      selectdate: [],
      //区列表
      areaCode: "",
      areaList: [],
      selectArea: [],

      //街道列表
      streeList: [],
      streeCode: "",
      selectStree: [],
      disabledStree: false,
      //社区列表
      communitylist: [],
      selectedcommunity: [],
      disabledCommunity: false,
      timetype: "day",
      title: "",
      totalData: {},
      settingsparam: { label: { show: true }, stack: { xxx: [] } },

      //小程序资源统计访问  下拉框
      miniVisit: "1",
      miniVisitOption: [
        {label: "访问次数",value: "1"},
        {label: "访问人数",value: "2"},
      ],
      //链接资源统计访问  下拉框
      linkVisit: "1",
      linkVisitOption: [
        {label: "访问次数",value: "1"},
        {label: "访问人数",value: "2"},
      ],
      //链接资源统计
       timetype2: "day",
      title2: "",
      settingsparam2: { label: { show: true }, stack: { xxx: [] } },
       chartData2: {},
      talbeColumns2: [],
      tableData2: [],
      selectdate2: [],
    };
  },
  created() {
    this.$get("/gx/pc/organization/selectCascadeOrgPermission", {
      //加载城市列表
      adminType: parent.vue.loginInfo.userinfo.adminType || "", //超级管理员标识
      custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
      oemCode: parent.vue.oemInfo.oemCode,
      orgCode: "4401",
      orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode, //组织机构编码,目前只有广州市，先写死
    }).then((res) => {
      if (res.code == 200) {
        this.areaList = res.dataList.map((item, index) => {
          return {
            value: item.orgCode,
            label: item.orgName,
          };
        });
      }
    });
  },
  mounted() {
    this.loadTotalData();
  },
  watch: {},
  methods: {
    //区选中,获取街道列表
    loadAreaList(value) {
      if (value.length > 1) {
        //多选区时，禁用街道和社区
        this.streeCode = [];
        this.selectStree = "";
        this.communityCode = [];
        this.communitylist = [];
        this.streeList = [];
        this.selectedcommunity = "";
        this.disabledStree = true;
        this.disabledCommunity = true;
        return;
      } else if (value.length == 0) {
        this.streeCode = [];
        this.selectStree = "";
        this.communityCode = [];
        this.communitylist = [];
        this.streeList = [];
        this.selectedcommunity = "";
        return;
      }
      this.disabledStree = false;
      this.disabledCommunity = false;
      this.areaCode = value.join(",");
      this.$get("/gx/pc/organization/selectCascadeOrgPermission", {
        adminType: parent.vue.loginInfo.userinfo.adminType || "", //超级管理员标识
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        oemCode: parent.vue.oemInfo.oemCode,
        orgCode: this.areaCode,
        orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
      }).then((res) => {
        if (res.code == 200) {
          this.streeList = res.dataList.map((item, index) => {
            return {
              value: item.orgCode,
              label: item.orgName,
            };
          });
        }
      });
    },
    //街道选中，获取社区列表
    loadStreeList(value) {
      if (value.length > 1 || this.selectArea.length > 1) {
        //禁用社区
        this.communityCode = [];
        this.communitylist = [];
        this.selectedcommunity = "";
        this.disabledCommunity = true;
        return;
      } else if (value.length == 0) {
        this.communityCode = [];
        this.communitylist = [];
        this.selectedcommunity = "";
        return;
      }
      this.disabledCommunity = false;
      this.streeCode = value.join(",");
      this.$get("/gx/pc/organization/selectCascadeOrgPermission", {
        adminType: parent.vue.loginInfo.userinfo.adminType || "", //超级管理员标识
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        oemCode: parent.vue.oemInfo.oemCode,
        orgCode: this.streeCode,
        orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
      }).then((res) => {
        if (res.code == 200) {
          this.communitylist = res.dataList.map((item, index) => {
            return {
              value: item.orgCode,
              label: item.orgName,
            };
          });
        }
      });
    },
    //选中社区
    loadCommunityList(value) {
      this.communityCode = value.join(",");
    },
    loadTotalData() {
      //加载全局汇总
      this.$Message.loading({
        content: "数据加载中...",
        duration: 0,
      });
      let codes = "";
      if (this.selectArea.length > 1 || this.selectStree.length == 0) {
        codes = this.selectArea.join(",");
      } else if (
        this.selectStree.length > 1 ||
        this.selectedcommunity.length == 0
      ) {
        codes = this.selectStree.join(",");
      } else {
        codes = this.selectedcommunity.join(",");
      }
      this.$get("/testjson/statistics/menutotaldata.json", {
        codes: codes,
        oemCode: parent.vue.oemInfo.oemCode,
      })
        .then((res) => {
          if (res && res.data) {
            this.totalData = res.data;
          }
          this.$Message.destroy();
          this.loadPublishData();
          this.loadPublishData2();
        })
        .catch((err) => {
          this.$Message.destroy();
        });
    },
    loadPublishData() {
      //读取栏目数
      var starttime = "";
      var endtime = "";
      var now = new Date();
      switch (this.timetype) {
        case "all":
          starttime = "2000-01-01 00:00:00.00";
          endtime = this.$core.formatDate(now, "yyyy-MM-dd 23:59:59.59");
          this.title = "全部";
          break;
        case "day":
           let yesterday = now.getTime() - 1000*60*60*24;
          starttime = this.$core.formatDate(new Date(yesterday), "yyyy-MM-dd 00:00:00.00");
          endtime = this.$core.formatDate(new Date(yesterday), "yyyy-MM-dd 23:59:59.59");
          this.title = "昨天";
          break;
        case "week":
          starttime = this.$core.formatDate(
            new Date(
            now.getTime() - 1000*60*60*24 * 7),
            "yyyy-MM-dd 00:00:00.00"
          );
          endtime = this.$core.formatDate(
            new Date(
              now.getTime() - 1000*60*60*24
            ),
            "yyyy-MM-dd 23:59:59.59"
          );
          this.title = "前七天";
          break;
        case "month":
         starttime = this.$core.formatDate(
            new Date(now.getTime() - 1000*60*60*24 * 30),
            "yyyy-MM-dd 00:00:00.00"
          );
          endtime = this.$core.formatDate(
            new Date(now.getTime() - 1000*60*60*24),
            "yyyy-MM-dd 23:59:59.59"
          );
          this.title = "前30天";
          break;
        default:
          if (
            this.selectdate.length > 1 &&
            this.selectdate[0] != "" &&
            this.selectdate[1] != ""
          ) {
            starttime = this.$core.formatDate(
              this.selectdate[0],
              "yyyy-MM-dd 00:00:00.00"
            );
            endtime = this.$core.formatDate(
              this.selectdate[1],
              "yyyy-MM-dd 23:59:59.59"
            );
            this.title =
              starttime.substring(0, 10) + "~" + endtime.substring(0, 10);
          }
          break;
      }
      if (starttime == "" || endtime == "") {
        this.$Message.error({ content: "请选择时间段", background: true });
        return;
      }
      this.chartData = {};
      this.$Message.loading({
        content: "数据加载中...",
        duration: 0,
      });

      let codes = "";
      if (this.selectArea.length > 1 || this.selectStree.length == 0) {
        codes = this.selectArea.join(",");
      } else if (
        this.selectStree.length > 1 ||
        this.selectedcommunity.length == 0
      ) {
        codes = this.selectStree.join(",");
      } else {
        codes = this.selectedcommunity.join(",");
      }
      this.$get("/testjson/statistics/menupublishdata.json", {
        codes: codes,
        startTime: starttime,
        endTime: endtime,
        oemCode: parent.vue.oemInfo.oemCode,
        miniVisit: this.miniVisit  // 访问类型
      })
        .then((res) => {
          if (res && res.data) {
            this.chartData = res.data;
            this.createTableData(this.chartData.rows || []);
          }
          this.$Message.destroy();
        })
        .catch((err) => {
          this.$Message.destroy();
        });
    },
    createTableData(chartData) {
      //将图表数据转化为表格数据
      this.talbeColumns = [{ title: "分类", key: "title", minWidth: 120 }];
      this.tableData = [];
      //console.log(chartData);
      var data = [];
      chartData.forEach((item, index) => {
        var i = 0;
        for (var key in item) {
          if (Number(item[key])) {
            var ii = i++;
            if (!data[ii]) data.push({ title: key });
            data[ii]["value" + index] = item[key];
          } else {
            this.talbeColumns.push({
              title: item[key],
              key: "value" + index,
              minWidth: item[key].length * 20 + 30,
            });
          }
        }
      });
      this.tableData = data;
    },
    exportData() {
      this.$refs.lftable.exportCsv({
        filename: this.title + "小程序资源数--按" + (this.miniVisit == '1' ? "访问次数统计" : "访问人数统计"),
      });
    },

    //链接资源统计
    loadPublishData2() {
      //读取栏目数
      var starttime = "";
      var endtime = "";
      var now = new Date();
      switch (this.timetype2) {
        case "all":
          starttime = "2000-01-01 00:00:00.00";
          endtime = this.$core.formatDate(now, "yyyy-MM-dd 23:59:59.59");
          this.title2 = "全部";
          break;
        case "day":
           let yesterday = now.getTime() - 1000*60*60*24;
          starttime = this.$core.formatDate(new Date(yesterday), "yyyy-MM-dd 00:00:00.00");
          endtime = this.$core.formatDate(new Date(yesterday), "yyyy-MM-dd 23:59:59.59");
          this.title2 = "昨天";
          break;
        case "week":
          starttime = this.$core.formatDate(
            new Date(
            now.getTime() - 1000*60*60*24 * 7),
            "yyyy-MM-dd 00:00:00.00"
          );
          endtime = this.$core.formatDate(
            new Date(
              now.getTime() - 1000*60*60*24
            ),
            "yyyy-MM-dd 23:59:59.59"
          );
          this.title2 = "前七天";
          break;
        case "month":
         starttime = this.$core.formatDate(
            new Date(now.getTime() - 1000*60*60*24 * 30),
            "yyyy-MM-dd 00:00:00.00"
          );
          endtime = this.$core.formatDate(
            new Date(now.getTime() - 1000*60*60*24),
            "yyyy-MM-dd 23:59:59.59"
          );
          this.title2 = "前30天";
          break;
        default:
          if (
            this.selectdate2.length > 1 &&
            this.selectdate2[0] != "" &&
            this.selectdate2[1] != ""
          ) {
            starttime = this.$core.formatDate(
              this.selectdate[0],
              "yyyy-MM-dd 00:00:00.00"
            );
            endtime = this.$core.formatDate(
              this.selectdate[1],
              "yyyy-MM-dd 23:59:59.59"
            );
            this.title2 =
              starttime.substring(0, 10) + "~" + endtime.substring(0, 10);
          }
          break;
      }
      if (starttime == "" || endtime == "") {
        this.$Message.error({ content: "请选择时间段", background: true });
        return;
      }
      this.chartData2 = {};
      this.$Message.loading({
        content: "数据加载中...",
        duration: 0,
      });

      let codes = "";
      if (this.selectArea.length > 1 || this.selectStree.length == 0) {
        codes = this.selectArea.join(",");
      } else if (
        this.selectStree.length > 1 ||
        this.selectedcommunity.length == 0
      ) {
        codes = this.selectStree.join(",");
      } else {
        codes = this.selectedcommunity.join(",");
      }
      this.$get("/testjson/statistics/menupublishdata.json", {
        codes: codes,
        startTime: starttime,
        endTime: endtime,
        oemCode: parent.vue.oemInfo.oemCode,
        linkVisit: this.linkVisit  // 访问类型
      })
        .then((res) => {
          if (res && res.data) {
            this.chartData2 = res.data;
            this.createTableData2(this.chartData2.rows || []);
          }
          this.$Message.destroy();
        })
        .catch((err) => {
          this.$Message.destroy();
        });
    },
    createTableData2(chartData) {
      //将图表数据转化为表格数据
      this.talbeColumns2 = [{ title: "分类", key: "title", minWidth: 120 }];
      this.tableData2 = [];
      //console.log(chartData);
      var data = [];
      chartData.forEach((item, index) => {
        var i = 0;
        for (var key in item) {
          if (Number(item[key])) {
            var ii = i++;
            if (!data[ii]) data.push({ title: key });
            data[ii]["value" + index] = item[key];
          } else {
            this.talbeColumns2.push({
              title: item[key],
              key: "value" + index,
              minWidth: item[key].length * 20 + 30,
            });
          }
        }
      });
      this.tableData2 = data;
    },
    exportData2() {
      this.$refs.lftable2.exportCsv({
        filename: this.title2 + "小程序资源数--按" + (this.linkVisit == '1' ? "访问次数统计" : "访问人数统计"),
      });
    },
  },
};
</script>

<style lang="less" scoped>
.tooldiv {
  width: 60px;
  line-height: 30px;
  text-align: right;
  white-space: nowrap;
}
.cardsty {
  min-width: 250px;
  min-width: 400px;
  width: 30%;
  margin-right: 3%;
  background: #2db7f5;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.cardsty p {
  text-align: left;
  font-size: 18px;
  margin-top: 20px;
}
.cardsty span {
  margin-right: 30px;
}
</style>
